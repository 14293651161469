import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import SliderSection from "../components/Slider";
import AboutSection from "../components/AboutSection";
import WhySection from "../components/WhySection";
import TestimonialSection from "../components/Testmonials";
import ContactUs from "../components/ContactUs";
import InfoSection from "../components/InfoSection";
import TeamComponent from "../components/TeamSection";
import Filter from "../components/Filter";
import { useFormik } from "formik";
import Products from "../components/Products";
import Footer from "../components/Footer";
import ScrollToTop from "../utils/ScrooltoTop";
import { useTranslation } from "react-i18next";
import { BsDiamondFill } from "react-icons/bs";
import { httpReauest } from "../utils/httpRequest";
import Loading from "../components/common/Loading";
import { CDN_BASE_URL } from "../config";
import { Link, useParams } from "react-router-dom";
import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { Helmet } from "react-helmet";

const ProjectsDetailsPage = () => {
  const [loading, setLoading] = useState(true);
  const [imagegallery, setimagegallery] = useState([]);
  const [data, setData] = useState();
  const { id } = useParams();

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    setLoading(true);
    const res = await httpReauest("GET", "/project/" + id, {}, {});
    setData(res.data.data);
    res.data.data?.images?.map((item) =>
      setimagegallery((cur) => [
        ...cur,
        {
          original: CDN_BASE_URL + item,
          thumbnail: CDN_BASE_URL + item,
          renderItem: () => (
            <img
              src={CDN_BASE_URL + item}
              className="w-full"
              alt={data?.name}
            />
          ),
        },
      ])
    );
    setLoading(false);
  }

  const [t, i18n] = useTranslation("global");
  return (
    <div className=" w-full ">
      <Helmet>
        <title>{data?.name}</title>
        <meta name="og:title" content={data?.name} />
      </Helmet>
      <Header />
      {loading ? (
        <Loading />
      ) : (
        <div className=" w-full container  gap-4 py-8 px-[10px] lg:px-10 xl:px-12 bg-white text-right">
          <div className="lg:col-span-4 w-full">
            <span className="text-center text-[14px] lg:text-[17px]">
              <h2
                dir={i18n.language === "en" ? "rtl" : "ltr"}
                className="font-bold text-[28px] md:text-[40px]  2xl:text-[40px] flex items-center gap-2 justify-center mb-3"
              >
                {data?.name}
                <BsDiamondFill size={22} color="#EF4b4c" />
              </h2>
              <div dangerouslySetInnerHTML={{ __html: data?.content }} />
              <div className="mt-4 w-full"></div>
            </span>
            <ReactImageGallery items={imagegallery} />
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default ProjectsDetailsPage;
