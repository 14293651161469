import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaAngleDown } from "react-icons/fa";
import { Link } from "react-router-dom";
import Flag from "react-flagkit";

const Header = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [t, i18n] = useTranslation("global");
  const handleChangeLanguge = (e) => {
    i18n.changeLanguage(e);
  };

  // async function getIp() {
  //   const response = await fetch("https://geolocation-db.com/json/");
  //   const data = await response.json();
  //   if (data.country_code === "IR") {
  //     i18n.changeLanguage("fa");
  //   } else if (
  //     data?.country_code === "SA" ||
  //     data?.country_code === "IQ" ||
  //     data?.country_code === "OM" ||
  //     data?.country_code === "AE"
  //   ) {
  //     i18n.changeLanguage("ar");
  //   } else {
  //     i18n.changeLanguage("en");
  //   }
  // }

  useEffect(() => {
    // getIp();
  }, []);

  return (
    <header className="header_section bg-[#43506C] text-[#e9e9eb]">
      <div className="lg:container-fluid">
        <nav className="navbar  navbar-expand-lg  custom_nav-container items-center grid grid-cols-2 lg:flex justify-between lg:px-5">
          <Link className="" to="/">
            <span>
              <img
                style={{ filter: "brightness(0) invert(1)" }}
                className="w-[150px] lg:w-[190px] object-contain filter "
                src="/web1.png"
                alt=""
              />
            </span>
          </Link>

          <div className="flex items-center gap-2">
            <span
              className="relative lg:ml-[30px]  lg:mx-0 col-span-2 enter-div order-1 lg:order-3"
              aria-current="page"
              to="/"
            >
              <span
                onClick={() => (show2 ? setShow2(false) : setShow2(true))}
                className="flex cursor-pointer text-[14px] "
              >
                <span
                  className="flex justify-center items-center gap-2 "
                  style={{ whiteSpace: "nowrap", fontSize: "14px" }}
                >
                  {i18n.language == "en" && <Flag country="US" />}
                  {i18n.language == "fa" && <Flag country="IR" />}
                  {i18n.language == "ar" && <Flag country="SA" />}{" "}
                  {i18n.language == "en" && "English - EN"}
                  {i18n.language == "fa" && "FA - فارسی "}
                  {i18n.language == "ar" && "AR - العربیه"}
                  <span>
                    <FaAngleDown size={15} />
                  </span>
                </span>

                {show2 && (
                  <span className={`drop_down h-fit`}>
                    <span
                      onClick={() => handleChangeLanguge("en")}
                      className=" cursor-pointer flex items-center gap-2 w-full  py-2 px-3 whitespace-nowrap"
                    >
                      <Flag country="US" />
                      <span> EN - English </span>
                    </span>
                    <span
                      onClick={() => handleChangeLanguge("fa")}
                      className=" cursor-pointer flex items-center gap-2 w-full py-2 px-3 whitespace-nowrap "
                    >
                      <Flag country="IR" />
                      <span> FA - فارسی </span>
                    </span>
                    <span
                      onClick={() => handleChangeLanguge("ar")}
                      className=" cursor-pointer flex items-center gap-2 w-full py-2 px-3 whitespace-nowrap "
                    >
                      <Flag country="SA" />
                      <span>AR - العربیه</span>
                    </span>
                  </span>
                )}
              </span>
            </span>
            <button
              class="navbar-toggler lg:hidden order-2"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setShow(!show)}
            >
              <span class="navbar-toggler-icon invert"></span>
            </button>
          </div>

          <div
            className={`${
              show ? "max-h-96" : "max-h-0"
            } navbar-collapse overflow-hidden lg:max-h-52 duration-300 w-full col-span-full lg:col-span-1`}
            id="navbarSupportedContent"
          >
            <div className="flex ml-auto  items-center pt-2 justify-center">
              <ul
                dir="rtl"
                className="navbar-nav  flex flex-col lg:flex-row font-[500] text-[18px]"
              >
                <li className="">
                  <Link className="nav-link" to="/">
                    {t("home")}
                  </Link>
                </li>
                <li className="">
                  <Link className="nav-link" to="/about">
                    {t("about-us")}
                  </Link>
                </li>
                <li className="">
                  <Link className="nav-link" to="/products">
                    {t("products")}
                  </Link>
                </li>
                <li className="">
                  <Link className="nav-link" to="/contact">
                    {t("contact-us")}
                  </Link>
                </li>
                {/* <li className="">
                  <Link className="nav-link" to="/service">
                    {t("services")}
                  </Link>
                </li>
                <li className="">
                  <Link className="nav-link" to="/projects">
                    {t("projects")}
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
