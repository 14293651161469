import React from "react";
import { Link } from "react-router-dom";

import {
  FaInstagram,
  FaFacebookF,
  FaTwitter,
  FaYoutube,
  FaLinkedinIn,
} from "react-icons/fa";
import { FaTelegram, FaWhatsapp } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const [t] = useTranslation("global");
  return (
    <>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3239.313571012158!2d51.11715307578936!3d35.71850597257526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzXCsDQzJzA2LjYiTiA1McKwMDcnMTEuMCJF!5e0!3m2!1sen!2s!4v1727100510852!5m2!1sen!2s"
        width="100%"
        height="400"
        allowfullscreen=""
        loading="lazy"
        title="Location"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
      <div className=" px-3 text-[#FFFF] bg-[#43506C]  lg:pt-[30px] lg:px-[150px]  z-50">
        <section class="">
          <div class="max-w-screen-xl px-4 pt-12 pb-2 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
            <nav class="flex flex-wrap justify-center -mx-5 -my-2">
              {/* <div class="px-5 py-2">
                <Link
                  to="/service"
                  class="text-base leading-6  hover:text-gray-600"
                >
                  {t("services")}
                </Link>
              </div> */}
              <div class="px-5 py-2">
                <Link
                  to="/about"
                  class="text-base leading-6  hover:text-gray-600"
                >
                  {t("about-us")}
                </Link>
              </div>
              <div class="px-5 py-2">
                <Link
                  to="/contact"
                  class="text-base leading-6 hover:text-gray-600"
                >
                  {t("contact-us")}
                </Link>
              </div>
              <div class="px-5 py-2">
                <Link
                  to="/products"
                  class="text-base leading-6  hover:text-gray-600"
                >
                  {t("products")}
                </Link>
              </div>
              <div class="px-5 py-2">
                <Link to="/" class="text-base leading-6  hover:text-gray-600">
                  {t("home")}
                </Link>
              </div>
            </nav>
            <div class="flex justify-center mt-8 space-x-6">
              <Link
                to="https://t.me/"
                target="_blank"
                class=" hover:text-gray-300"
              >
                <FaTelegram size={26} />
              </Link>
              <Link
                to="https://www.instagram.com/"
                target="_blank"
                class=" hover:text-gray-300"
              >
                <FaInstagram size={26} />
              </Link>
              <Link
                to="https://wa.me/message/989136690792"
                target="_blank"
                class=" hover:text-gray-300"
              >
                <FaWhatsapp size={26} />
              </Link>
            </div>
            <p className="text-center">{t("footer-addres")}</p>
            <p class="mt-8 text-base leading-6 text-center ">{t("reserved")}</p>
          </div>
        </section>
      </div>
    </>
  );
};

export default Footer;
