import React from "react";
import { FaPhoneAlt } from "react-icons/fa";
import {
  FaEnvelope,
  FaInstagram,
  FaPhone,
  FaRegEnvelope,
} from "react-icons/fa6";

const PriceModal = ({ setShare, t }) => {
  return (
    <>
      <div
        onClick={() => {
          setShare(false);
        }}
        className="fixed top-0 left-0 h-full w-full bg-[#00000070] z-40"
      ></div>
      <div className="fixed  left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2  bg-white p-4 rounded-[10px] z-50 text-[14px]">
        <div>
          <h2 className="text-[20px] font-[700] text-center">
            {t("get-price")}
          </h2>
        </div>
        <div className="grid grid-cols-2 pt-4 gap-5">
          <a
            href="tel:+982146869254"
            className="flex justify-center items-center flex-col"
          >
            <FaPhoneAlt size={29} />
            <p className="mt-2">{t("call")}</p>
          </a>
          <a
            href={`mailto:info@shahabcamping.ir`}
            className="flex justify-center items-center flex-col"
          >
            <FaRegEnvelope size={29} />
            <p className="mt-2">{t("email")}</p>
          </a>
        </div>
      </div>
    </>
  );
};

export default PriceModal;
