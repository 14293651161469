import React, { useEffect, useState } from "react";
import ProductCard from "./common/ProductCard";
import { httpReauest } from "../utils/httpRequest";
import Loading from "./common/Loading";
import ProductCardHome from "./common/ProductCardHome";
import Reveal from "./motion/Reveal";
import { useTranslation } from "react-i18next";

const BestProducts = () => {
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(false);
  const [t] = useTranslation("global");
  async function getData(params) {
    setloading(true);
    const res = await httpReauest("GET", "/product/popular", {}, {});
    setData(res.data.data);
    setloading(false);
  }

  useEffect(() => {
    getData();
  }, []);
  // const testData = [{img:'/aircraft.jpg' ,name:'ایرکرافت'}]
  return (
    <section className=" layout_padding2 text-center">
      <div className="container">
        <Reveal head>
          <div className="brand_heading">
            <h3 className="custom_heading text-[1.75rem]">
              {t("reccomend-products")}
            </h3>
          </div>
        </Reveal>
      </div>
      <div className=" gap-1 sm:gap-5 grid-cols-2 mt-4 grid sm:grid-cols-3  lg:grid-cols-4 px-[10px] sm:px-10 lg:px-20 xl:px-36">
        {loading ? (
          <Loading />
        ) : (
          data?.slice(0, 4).map((item) => <ProductCardHome data={item} />)
        )}
      </div>
    </section>
  );
};

export default BestProducts;
