import React from "react";
import { Link } from "react-router-dom";
import { CDN_BASE_URL } from "../../config";
import Reveal from "../motion/Reveal";
import { useTranslation } from "react-i18next";

const ProductCardHome = ({ data }) => {
  const [t, i18n] = useTranslation("global");
  return (
    <Reveal height>
      <div className="text-center shadow-md h-full relative p-0 rounded-[8px] overflow-hidden">
        <Link to={"/products/" + data?._id}>
          <div className="absolute left-0 top-0 w-full h-full bg-[#000]/60 z-10"></div>
          <img
            src={CDN_BASE_URL + data?.img}
            className="w-full h-[230px] lg:h-[300px] object-contain"
            alt=""
          />
          <div className=" absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-white z-20 w-full">
            <h6 className="mb-3 text-[12px] sm:text-[16px] px-4">
              {i18n.language == "fa"
                ? data?.name
                : i18n.language == "ar"
                ? data?.name_ar
                : data?.name_en}
            </h6>
          </div>
        </Link>
      </div>
    </Reveal>
  );
};

export default ProductCardHome;
