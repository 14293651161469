import React from "react";
import { Link } from "react-router-dom";
import { CDN_BASE_URL } from "../../config";
import Reveal from "../motion/Reveal";
import { useTranslation } from "react-i18next";

const ProductCard = ({ data, white }) => {
  const [t, i18n] = useTranslation("global");

  return (
    <Reveal height className={"shadow-md hover:scale-105 duration-150"}>
      <Link to={"/products/" + data?._id} className="">
        <div className=" brand_item-box text-center pb-3 h-full shadow-md">
          <div className="brand_img-box  bg-white ">
            <img
              src={CDN_BASE_URL + data?.img}
              className="w-full  p-3 h-[230px] lg:h-[300px] object-contain"
              alt=""
            />
          </div>
          <div
            className={`brand_detail-box ${
              white ? "text-white" : "text-black"
            }`}
          >
            <h6 className="mt-2 text-[14px] sm:text-[16px] px-4">
              {" "}
              {i18n.language == "fa"
                ? data?.name
                : i18n.language == "ar"
                ? data?.name_ar
                : data?.name_en}
            </h6>
          </div>
        </div>
      </Link>
    </Reveal>
  );
};

export default ProductCard;
